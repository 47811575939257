<template>
    <div
        class="flex space-x-1 rounded-lg bg-gray-100 dark:bg-gray-900 p-0.5"
        role="serviceSwitcher"
        aria-orientation="horizontal">
        <nuxt-link
            id="public"
            :to="useLocalePath()('/')"
            class="flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold lg:pr-3"
            :class="{'bg-white dark:bg-gray-800 shadow' : !isServicePage}"
            role="switch"
            type="button"
            :aria-selected="!isServicePage"
            tabindex="0"
            :data-headlessui-state="{'selected' : !isServicePage}"
            aria-controls="enter-public-space">
            <span class="lg:ml-2 text-gray-900 dark:text-gray-50">Öffentlich</span>
        </nuxt-link>
        <nuxt-link
            id="service"
            :to="useLocalePath()('/service')"
            class="flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold lg:pr-3"
            :class="{'bg-white dark:bg-gray-800 shadow' : isServicePage}"
            role="switch"
            type="button"
            :aria-selected="isServicePage"
            tabindex="-1"
            :data-headlessui-state="{'selected' : isServicePage}"
            aria-controls="enter-service-space">
            <span class="lg:ml-2 text-gray-600 dark:text-gray-300">Verwaltung</span>
        </nuxt-link>
    </div>
</template>

<script setup>
import {useRoute} from "vue-router";

const route = useRoute()
const isServicePage = computed(() => {
    return route.path.startsWith('/service')
})
</script>